import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MonitorIcon from "@mui/icons-material/Monitor";
import { hasIntegrationsByType } from "../../../../functions/getCloudReportInformation";
import GlobalConstants from "@constants";
import {
  checkPermissions,
  getManagedSecurityGroups,
  getSecurityGroups,
} from "../../../../functions/securityGroupHelper";
import { CircularProgress } from "@mui/material";
import { ViewCarousel } from "@mui/icons-material";
import React from "react";
import {
  hasAwsCloudContracts,
  hasAzureCloudContracts,
  hasGCPCloudContracts,
  hasOffice365CloudContracts,
} from "../../../../functions/contractHelper";
import { ShiOnePermission } from "../../../../auth/authorizationTypes";
import _ from "lodash";
import { navigateToInternalArchbee } from "../../../../functions/archbeeInternalRedirect";
import {
  AccountOverview,
  CloudAccount,
  ConfigurationItemDTO,
  ContentUnitDto,
  FSACacheResponse,
  IUserProfileCacheResponse,
  Team,
} from "@lib/ShiOneClient";
import { IntegrationsCollectorState } from "../../../../modules/rtk/integrationsCollector/integrationsCollectorTypes";
import { accountState } from "../../../../modules/rtk/account/accountTypes";
import { knowledgebaseReducer } from "../../../../modules/knowledgebase/reducers/knowledgebaseReducer";
import { backupReducer } from "../../../../modules/backup/reducers/backupReducer";
import { NavigationFeatureFlags } from "./SubNavGroups/navigationFeatureFlags";
import { HasPermissionFunctionType } from "../../../../auth/ClaimsProvider";
import { CloudReportType } from "../../../../modules/rtk/integrationsCollector/integrationsCollectorSlice";
import { NavItemType } from "./NavigationTypes";
import { LabsMenu } from "./SubNavGroups";
import GradingIcon from "@mui/icons-material/Grading";
import CloudIcon from "@mui/icons-material/Cloud";
import SettingsIcon from "@mui/icons-material/Settings";
import BusinessIcon from "@mui/icons-material/Business";

// This type will be used in a future story to clean up the code in ShiNavigationItems
export type ShiNavigationItemsProps = {
  userProfileResponse: IUserProfileCacheResponse;
  isShiUser: boolean;
  portalRole: string;
  integrationsCollection: IntegrationsCollectorState;
  accountsOverview: AccountOverview[];
  accounts: accountState;
  teamContracts: any;
  myTeams: Team[];
  knowledgebase: ReturnType<typeof knowledgebaseReducer>;
  myDevices: ConfigurationItemDTO[];
  cloudContracts: CloudAccount[];
  fsas: FSACacheResponse;
  backup: ReturnType<typeof backupReducer>;
  hasAzureCloudReports: CloudReportType;
  subDomain?: string;
  featureFlags: NavigationFeatureFlags;
  hasPermission: HasPermissionFunctionType;
};

export default function ShiNavigationItems({
  userProfileResponse,
  isShiUser,
  portalRole,
  integrationsCollection,
  teamContracts,
  myTeams,
  knowledgebase,
  myDevices,
  cloudContracts,
  fsas,
  backup,
  hasAzureCloudReports,
  subDomain,
  hasPermission,
}: ShiNavigationItemsProps): (NavItemType | undefined)[] {
  const {
    fetchBackupStatsComplete,
    backupStats,
  }: {
    fetchBackupStatsComplete: boolean;
    backupStats: any;
  } = backup;
  const profile = userProfileResponse.userProfile;

  const virtualAdmin = userProfileResponse.virtualAdmin;
  const { contentUnits, fetchingKnowledgebase } = knowledgebase;

  let loadingBackupStats = true;
  let hasBackupService = false;

  if (fetchBackupStatsComplete) {
    loadingBackupStats = false;
    if (backupStats && backupStats.length > 0) {
      hasBackupService = true;
    }
  }

  //Keeping for future use
  const isLocalShi =
    profile?.contact?.accountID === GlobalConstants.shiAutotaskAccountId;

  const isCustomer = !isLocalShi;
  const isAdmin = portalRole === "admin";
  const environmentStatus = {
    isDevelopmentEnv:
      window.config.REACT_APP_DIST_ENV ===
      GlobalConstants.environments.development,
    isIntegrationEnv:
      window.config.REACT_APP_DIST_ENV ===
      GlobalConstants.environments.integration,
    isStagingEnv:
      window.config.REACT_APP_DIST_ENV === GlobalConstants.environments.staging,
    isProductionEnv:
      window.config.REACT_APP_DIST_ENV ===
      GlobalConstants.environments.production,
    isDemoEnv:
      window.config.REACT_APP_DIST_ENV === GlobalConstants.environments.demo,
  };

  const shouldDisplayFullSettings = true;
  const shouldDisplayTeamSettings = false;

  const publicContentUnits: ContentUnitDto[] =
    knowledgebase && contentUnits && contentUnits.length > 0
      ? contentUnits
      : [];

  const hasAzureContracts = hasAzureCloudContracts(cloudContracts);
  const hasAwsContracts = hasAwsCloudContracts(cloudContracts);
  const hasOffice365Contracts = hasOffice365CloudContracts(cloudContracts);
  const hasGcpContracts = hasGCPCloudContracts(teamContracts, cloudContracts);
  const hasCloudAccounts =
    hasAzureContracts ||
    hasAwsContracts ||
    hasOffice365Contracts ||
    hasGcpContracts;

  const hasAzureComplianceIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.azureCompliance,
  );
  const hasAwsBillingReportIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.awsBillingReport,
  );
  const hasAzureGovernanceReportsIntegration = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.azureGovernanceReports,
  );
  const hasAzureRecommendations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.azureSimpleRecommendations,
  );
  const hasAzureBackupIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.azureBackups,
  );
  const hasGcpUsageReportIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.gcpUsageReport,
  );
  const hasDatadogUsageIntegration = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.datadogUsageReport,
  );

  const hasAzureIntegrations =
    hasAzureRecommendations ||
    hasAzureComplianceIntegrations ||
    hasAzureBackupIntegrations;
  const hasAzureReports =
    hasAzureCloudReports &&
    (hasAzureCloudReports.hasAzurePlanReportIntegration ||
      hasAzureCloudReports.hasAzureDirectConsumptionIntegration ||
      hasAzureCloudReports.hasAzureDevOpsReportIntegration);

  const showAzureNav =
    hasAzureReports || hasAzureContracts || hasAzureIntegrations;

  const hasAwsReports = hasAwsBillingReportIntegrations;
  const showAwsNav = hasAwsReports || hasAwsContracts;

  const hasGcpReports = hasGcpUsageReportIntegrations;
  const showGcpNav = hasGcpContracts || hasGcpReports;

  const hasDatadogUsageReports = hasDatadogUsageIntegration;

  const showOffice365Nav = hasOffice365Contracts || hasBackupService;
  const hasFSA =
    fsas &&
    fsas.flexibleServicesAgreements &&
    fsas.flexibleServicesAgreements.length > 0;
  const fsaID = hasFSA
    ? fsas.flexibleServicesAgreements &&
      fsas.flexibleServicesAgreements[0].contractId
    : undefined;
  const showCloudNav =
    showAzureNav ||
    showAwsNav ||
    hasAzureContracts ||
    hasAwsContracts ||
    showOffice365Nav ||
    hasAzureIntegrations ||
    showGcpNav ||
    hasGcpContracts ||
    hasDatadogUsageIntegration;

  const contosoDemo =
    profile?.contact?.accountID === GlobalConstants.demoAccounts.contoso;
  const myManagedSecurityGroups = getManagedSecurityGroups(
    profile?.contact?.id,
    myTeams,
  );

  const labAdministrator = getSecurityGroups(
    profile?.contact?.id,
    myTeams,
  ).some((team) => team.teamName === "SHI Labs Administrators");

  let hasAwsMasterPayerAccounts = false;

  if (cloudContracts && cloudContracts.length > 0) {
    cloudContracts.forEach((cc) => {
      if (
        cc.cloud === "AWS" &&
        cc.userDefinedFieldsJson &&
        cc.userDefinedFieldsJson !== "[]"
      ) {
        const awsPayerID = _.find(
          JSON.parse(cc.userDefinedFieldsJson),
          (udf) => {
            return udf.Name === "AWS Payer ID";
          },
        );
        if (awsPayerID && awsPayerID.Value) {
          hasAwsMasterPayerAccounts = true;
        }
      }
    });
  }

  let shouldDisplayAdmin = true;

  if (subDomain === "lpl" && profile?.contact?.accountID !== 175) {
    shouldDisplayAdmin = false;
  }

  const kbItems = _.sortBy(publicContentUnits, [
    function (cu) {
      return cu.title;
    },
  ]);

  const isResource = userProfileResponse.isResource;

  const hasDevices = myDevices?.length > 0;

  function showEndUserDevices() {
    return isAdmin && hasPermission(ShiOnePermission.managedDevicesRead);
  }
  const kbUnits = _.sortBy(publicContentUnits, [
    function (cu) {
      return cu.title;
    },
  ]).map((cu) => {
    return {
      id: "knowledgeBaseUnit-" + cu.identifier,
      type: "Link",
      displayName: cu.title!,
      path: cu.url,
      target: "_blank",
      visible: true,
    };
  });

  return [
    {
      id: "shi",
      type: "Group",
      displayName: "SHI",
      icon: <BusinessIcon />,
      visible: isLocalShi,
      children: [
        {
          id: "shiMyAccounts",
          type: "Link",
          displayName: "My Accounts",
          path: "/shi-internal/myaccounts",
          visible: true,
        },
        {
          id: "shiInternalDocs",
          type: "Function",
          displayName: "Documentation",
          function: () => navigateToInternalArchbee(),
          visible: true,
        },
        {
          id: "shiContractMapping",
          type: "Link",
          displayName: "Contract Mapping",
          path: "/shi-internal/contractmapping",
          visible: isAdmin,
        },
        {
          id: "shiMarketplaceAccounts",
          type: "Link",
          displayName: "Marketplace Accounts",
          path: "/shi-internal/marketplaceAccounts",
          visible: isAdmin,
        },
        {
          id: "shiUserLabsOnDemandRequestsManagement",
          type: "Link",
          displayName: "Lab Requests Management",
          path: "/labs/on-demand-requests/management",
          visible: labAdministrator,
        },
      ],
    },
    {
      id: "supportCenter",
      type: "Group",
      displayName: "Support",
      icon: (
        <LocalHospitalIcon
          sx={{ fontSize: "large" }}
          className={"fas fa-medkit"}
        />
      ),
      visible: true,
      children: [
        {
          id: "newrequest",
          type: "Link",
          displayName: "New Request",
          path: GlobalConstants.knownPaths.supportCenter_NewRequest,
          visible: true,
        },
        {
          id: "requests",
          type: "Link",
          displayName: "Requests",
          path: "/support-center/requests",
          visible: true,
        },
        {
          id: "serviceReports",
          type: "Link",
          displayName: "Reports",
          path: "/support-center/service-report",
          visible: true,
        },
        {
          id: "knowledgeBase",
          type: "Group",
          displayName: "Knowledge Base",
          visible: fetchingKnowledgebase || kbItems.length > 0,
          children: [...kbUnits],
        },
        {
          id: "myDevices",
          type: "Link",
          displayName: "My Devices",
          path: "/support-center/mydevices",
          visible: !virtualAdmin && hasDevices,
        },
        {
          id: "allContracts",
          type: "Link",
          displayName: "Contracts",
          path: "/services/contracts",
          visible:
            isLocalShi &&
            isAdmin &&
            hasPermission(ShiOnePermission.serviceProjectRead),
        },
        {
          id: "flexibleServiceAgreement",
          type: "Link",
          displayName: "Flexible Service Agreement",
          path: "/services/contracts/" + fsaID,
          visible:
            isLocalShi &&
            hasFSA &&
            isAdmin &&
            hasPermission(ShiOnePermission.serviceProjectRead),
        },
      ],
    },
    {
      id: "cloud",
      type: "Group",
      displayName: "Cloud",
      icon: loadingBackupStats ? <CircularProgress size={18} /> : <CloudIcon />,
      visible:
        (!isAdmin && checkPermissions(myTeams, "cloud/")) ||
        (isAdmin && showCloudNav),
      children: [
        {
          id: "cloudAccounts",
          type: "Link",
          displayName: "Accounts",
          path: "/cloud/accounts",
          visible:
            hasPermission(ShiOnePermission.awsAccountRead) ||
            hasPermission(ShiOnePermission.azureAccountRead) ||
            hasPermission(ShiOnePermission.o365AccountRead) ||
            hasPermission(ShiOnePermission.gcpAccountRead),
        },
        {
          id: "cloudAws",
          type: "Group",
          displayName: "AWS",
          visible:
            hasPermission(ShiOnePermission.awsAccountRead) ||
            hasPermission(ShiOnePermission.awsConsumptionRead) ||
            hasPermission(ShiOnePermission.awsBlueprintDeploy) ||
            (isAdmin && hasAwsMasterPayerAccounts) ||
            hasPermission(ShiOnePermission.awsInvoiceRead) ||
            hasPermission(ShiOnePermission.awsMonitoringAlertRead) ||
            hasPermission(ShiOnePermission.awsRecommendationRead) ||
            hasPermission(ShiOnePermission.awsComplianceRead),
          children: [
            {
              id: "cloudAwsAccounts",
              type: "Link",
              displayName: "Accounts",
              path: "/cloud/aws/accounts",
              visible: hasPermission(ShiOnePermission.awsAccountRead),
            },
            {
              id: "cloudAwsReports",
              type: "Link",
              displayName: "Consumption",
              path: "/cloud/aws/reports",
              visible: hasPermission(ShiOnePermission.awsConsumptionRead),
            },
            {
              id: "cloudAwsBlueprints",
              type: "Link",
              displayName: "Blueprints",
              path: "/cloud/aws/blueprints",
              visible: hasPermission(ShiOnePermission.awsBlueprintDeploy),
            },
            {
              id: "cloudAwsCostAllocationTags",
              type: "Link",
              displayName: "Cost Allocation Tags",
              path: "/cloud/aws/cat",
              visible: isAdmin && hasAwsMasterPayerAccounts,
            },
            {
              id: "cloudAwsInvoices",
              type: "Link",
              displayName: "Invoices",
              path: "/cloud/aws/invoices",
              visible: hasPermission(ShiOnePermission.awsInvoiceRead),
            },
            {
              id: "cloudAwsMonitoring",
              type: "Link",
              displayName: "Monitoring",
              path: "/cloud/aws/monitoring",
              visible: hasPermission(ShiOnePermission.awsMonitoringAlertRead),
            },
            {
              id: "cloudAwsRecommendations",
              type: "Link",
              displayName: "Recommendations",
              path: "/cloud/aws/recommendations",
              visible: hasPermission(ShiOnePermission.awsRecommendationRead),
            },
            {
              id: "cloudAwsGovernanceReports",
              type: "Link",
              displayName: "Governance",
              path: "/cloud/aws/governance",
              visible: hasPermission(ShiOnePermission.awsComplianceRead),
            },
          ],
        },
        {
          id: "cloudAzure",
          type: "Group",
          displayName: "Azure",
          visible:
            (hasPermission(ShiOnePermission.azureBackupAlertRead) &&
              hasAzureBackupIntegrations) ||
            hasPermission(ShiOnePermission.azureBlueprintDeploy) ||
            hasPermission(ShiOnePermission.azureInvoiceRead) ||
            hasPermission(ShiOnePermission.azureMonitoringAlertRead) ||
            hasPermission(ShiOnePermission.azureRecommendationRead) ||
            hasPermission(ShiOnePermission.azureConsumptionRead) ||
            (hasAzureComplianceIntegrations &&
              hasPermission(ShiOnePermission.azureComplianceRead)) ||
            (hasAzureGovernanceReportsIntegration &&
              hasPermission(ShiOnePermission.azureComplianceRead)) ||
            hasPermission(ShiOnePermission.azureAccountRead),
          children: [
            {
              id: "cloudAzureBackup",
              type: "Link",
              displayName: "Backup",
              path: "/cloud/azure/backup",
              visible:
                hasPermission(ShiOnePermission.azureBackupAlertRead) &&
                hasAzureBackupIntegrations,
            },
            {
              id: "cloudAzureBlueprints",
              type: "Link",
              displayName: "Blueprints",
              path: "/cloud/azure/blueprints",
              visible: hasPermission(ShiOnePermission.azureBlueprintDeploy),
            },
            {
              id: "cloudAzureInvoices",
              type: "Link",
              displayName: "Invoices",
              path: "/cloud/azure/invoices",
              visible: hasPermission(ShiOnePermission.azureInvoiceRead),
            },
            {
              id: "cloudAzureMonitoring",
              type: "Link",
              displayName: "Monitoring",
              path: "/cloud/azure/monitoring",
              visible: hasPermission(ShiOnePermission.azureMonitoringAlertRead),
            },
            {
              id: "cloudAzureRecommendations",
              type: "Link",
              displayName: "Recommendations",
              path: "/cloud/azure/recommendations",
              visible: hasPermission(ShiOnePermission.azureRecommendationRead),
            },
            {
              id: "cloudAzureRecommendationsAllUpReport",
              type: "Link",
              displayName: "All-Up Recommendations",
              path: "/cloud/azure/reports/all-up/recommendations",
              visible: hasPermission(ShiOnePermission.azureRecommendationRead),
            },
            {
              id: "cloudAzureReports",
              type: "Link",
              displayName: "Reports",
              path: "/cloud/azure/reports",
              visible: hasPermission(ShiOnePermission.azureConsumptionRead),
            },
            {
              id: "cloudAzureSecurityCompliance",
              type: "Link",
              displayName: "Security & Compliance",
              path: "/cloud/azure/securitycompliance",
              visible:
                hasAzureComplianceIntegrations &&
                hasPermission(ShiOnePermission.azureComplianceRead),
            },
            {
              id: "cloudAzureGovernance",
              type: "Link",
              displayName: "Governance",
              path: "/cloud/azure/governance",
              visible:
                hasAzureGovernanceReportsIntegration &&
                hasPermission(ShiOnePermission.azureComplianceRead),
            },
            {
              id: "cloudAzureSubscriptions",
              type: "Link",
              displayName: "Subscriptions",
              path: "/cloud/azure/subscriptions",
              visible: hasPermission(ShiOnePermission.azureAccountRead),
            },
          ],
        },
        {
          id: "cloudGcp",
          type: "Group",
          displayName: "GCP",
          visible:
            hasPermission(ShiOnePermission.gcpInvoiceRead) ||
            hasPermission(ShiOnePermission.gcpAccountRead) ||
            hasPermission(ShiOnePermission.gcpConsumptionRead),
          children: [
            {
              id: "cloudGcpInvoices",
              type: "Link",
              displayName: "Invoices",
              path: "/cloud/gcp/invoices",
              visible: hasPermission(ShiOnePermission.gcpInvoiceRead),
            },
            {
              id: "cloudGcpProjects",
              type: "Link",
              displayName: "Projects",
              path: "/cloud/gcp/projects",
              visible: hasPermission(ShiOnePermission.gcpAccountRead),
            },
            {
              id: "cloudGcpReports",
              type: "Link",
              displayName: "Reports",
              path: "/cloud/gcp/reports",
              visible: hasPermission(ShiOnePermission.gcpConsumptionRead),
            },
          ],
        },
        {
          id: "cloudOffice365",
          type: "Group",
          displayName: "Office 365",
          visible:
            (hasPermission(ShiOnePermission.o365BackupStatusRead) &&
              hasBackupService &&
              fetchBackupStatsComplete) ||
            hasPermission(ShiOnePermission.o365InvoiceRead) ||
            hasPermission(ShiOnePermission.o365AccountRead),
          children: [
            {
              id: "cloudOffice365Backup",
              type: "Link",
              displayName: "Backup",
              path: "/cloud/office365/backup",
              visible:
                hasPermission(ShiOnePermission.o365BackupStatusRead) &&
                hasBackupService &&
                fetchBackupStatsComplete,
            },
            {
              id: "cloudOffice365Invoices",
              type: "Link",
              displayName: "Invoices",
              path: "/cloud/office365/invoices",
              visible: hasPermission(ShiOnePermission.o365InvoiceRead),
            },
            {
              id: "cloudOffice365Subscriptions",
              type: "Link",
              displayName: "Subscriptions",
              path: "/cloud/office365/subscriptions",
              visible: hasPermission(ShiOnePermission.o365AccountRead),
            },
          ],
        },
        {
          id: "cloudDatadog",
          type: "Group",
          displayName: "Datadog",
          visible:
            (!isAdmin && checkPermissions(myTeams, "cloud/datadog")) ||
            (isAdmin && hasDatadogUsageReports) ||
            (isAdmin && contosoDemo),
          children: [
            {
              id: "cloudDatadogUsageReports",
              type: "Link",
              displayName: "Reports",
              path: "/cloud/datadog/reports",
              visible:
                (!isAdmin &&
                  checkPermissions(
                    myTeams,
                    "cloud/datadog/report",
                    "report:ReadReports",
                  ) &&
                  hasDatadogUsageIntegration) ||
                (isAdmin && hasDatadogUsageIntegration) ||
                (isAdmin && contosoDemo),
            },
          ],
        },
      ],
    },
    {
      id: "assets",
      type: "Group",
      displayName: "Assets",
      icon: <MonitorIcon />,
      visible:
        (checkPermissions(myTeams, "assets/") && !contosoDemo) ||
        showEndUserDevices(),
      children: [
        {
          id: "managedDevices",
          type: "Group",
          displayName: "Managed Devices",
          visible: showEndUserDevices(),
          children: [
            {
              id: "endUser",
              type: "Link",
              displayName: "End User",
              path: "/assets/managed-devices/end-user",
              visible: showEndUserDevices(),
            },

            {
              id: "network",
              type: "Link",
              displayName: "Network",
              path: "/assets/managedDevices/network",
              visible: checkPermissions(myTeams, "assets/network"),
            },
            {
              id: "server",
              type: "Link",
              displayName: "Server",
              path: "/assets/managedDevices/server",
              visible: checkPermissions(myTeams, "assets/server"),
            },
          ],
        },
      ],
    },
    {
      id: "assessments",
      type: "Link",
      displayName: "Assessments",
      path: "/assessments",
      icon: <GradingIcon />,
      visible: true,
    },
    LabsMenu(),
    {
      id: "settings",
      type: "Group",
      displayName: "Settings",
      icon: <SettingsIcon />,
      visible:
        (isAdmin && shouldDisplayAdmin) || myManagedSecurityGroups.length > 0,
      children: [
        {
          id: "settingsGeneral",
          type: "Link",
          displayName: "General",
          path: "/settings/general",
          visible: isAdmin,
        },
        {
          id: "settingsIntegrations",
          type: "Link",
          displayName: "Integrations",
          path: "/settings/integrations",
          visible: true,
        },
        {
          id: "settingsUserManagement",
          type: "Link",
          displayName: "User Management",
          path: "/settings/user-management",
          visible: isAdmin && shouldDisplayFullSettings,
        },
        {
          id: "settingsSecurityGroups",
          type: "Link",
          displayName: "Security Groups",
          path: "/settings/team-management",
          visible:
            (shouldDisplayFullSettings && shouldDisplayTeamSettings) ||
            (isAdmin && myManagedSecurityGroups.length > 0),
        },
        {
          id: "settingsSecurityGroupsESG",
          type: "Link",
          displayName: "Security Groups",
          path: "/settings/esg",
          visible:
            shouldDisplayFullSettings &&
            !shouldDisplayTeamSettings &&
            isAdmin &&
            isCustomer &&
            (!isShiUser || isResource),
        },
        {
          id: "costCenters",
          type: "Link",
          displayName: "Cost Centers",
          path: "/settings/cost-centers",
          visible: isAdmin && hasCloudAccounts,
        },
      ],
    },
    {
      id: "shiOneDemos",
      type: "Link",
      displayName: "SHI One Demos",
      path: "/demos",
      icon: <ViewCarousel />,
      visible:
        environmentStatus.isDevelopmentEnv ||
        environmentStatus.isIntegrationEnv,
    },
    {
      id: "demoShiOne",
      type: "Function",
      displayName: "Demo SHI One",
      function: () => window.open("https://demo.one.shi.com/home", "_blank"),
      icon: <ViewCarousel />,
      visible:
        environmentStatus.isStagingEnv ||
        environmentStatus.isProductionEnv ||
        environmentStatus.isDemoEnv,
    },
  ];
}
