import { EmbedParams, EmbedToken, ReportClient } from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";

const reportClient = new ReportClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getEmbedParams(
  reportKey: string,
  filter: string | undefined
): Promise<EmbedParams> {
  return reportClient.getEmbedParams(reportKey, filter);
}

export async function getNewAccessToken(
  reportKey: string,
  filter: string | undefined
): Promise<EmbedToken> {
  return reportClient.getNewAccessToken(reportKey, filter);
}
