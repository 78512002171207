import { ProjectClient, ProjectListResponse } from "@lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";

const api = new ProjectClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getProjects(): Promise<ProjectListResponse> {
  return await api.getProjectList();
}

export async function getProjectById(projectId: number): Promise<unknown> {
  return await api.getProjectById(projectId);
}
