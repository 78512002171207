import { IConfig } from "@lib/ShiOneClient";
import { ApiClientConfig } from "../../../auth/ApiClientConfig";

// Helper to create an instance of the API you need
export function useApi<ClientType>(
  type: new (config: IConfig, endpoint: string) => ClientType
) {
  return new type(
    ApiClientConfig,
    (window as any).config.REACT_APP_API_ENDPOINT
  );
}
