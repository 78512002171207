import {
  CostAnalyticProviders,
  CostAnalyticsClient,
  CostAnalyticsResponse,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new CostAnalyticsClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function searchAnalyticsByProvider(
  costAnalyticProvider: CostAnalyticProviders
): Promise<CostAnalyticsResponse> {
  return api.searchAnalyticsByProvider(costAnalyticProvider);
}
