import {
  ADD_CONTRACTS_TO_TEAM,
  ADD_CONTRACTS_TO_TEAM_ERROR,
  ADD_CONTRACTS_TO_TEAM_SUCCESS,
  ADD_TEAM_CONFIG_ITEMS,
  ADD_TEAM_CONFIG_ITEMS_ERROR,
  ADD_TEAM_CONFIG_ITEMS_SUCCESS,
  ADD_USERS_TO_TEAM,
  ADD_USERS_TO_TEAM_ERROR,
  ADD_USERS_TO_TEAM_SUCCESS,
  CREATE_TEAM,
  CREATE_TEAM_ERROR,
  CREATE_TEAM_SUCCESS,
  GET_ACCOUNT_TEAMS,
  GET_ACCOUNT_TEAMS_ERROR,
  GET_ACCOUNT_TEAMS_SUCCESS,
  GET_TEAM_CONTRACTS,
  GET_TEAM_CONTRACTS_ERROR,
  GET_TEAM_CONTRACTS_SUCCESS,
  MODIFY_CONTRACT_MAPPING,
  MODIFY_CONTRACT_MAPPING_ERROR,
  MODIFY_CONTRACT_MAPPING_SUCCESS,
  REMOVE_CONTRACTS_FROM_TEAM,
  REMOVE_CONTRACTS_FROM_TEAM_ERROR,
  REMOVE_CONTRACTS_FROM_TEAM_SUCCESS,
  REMOVE_TEAM,
  REMOVE_TEAM_CONFIG_ITEMS,
  REMOVE_TEAM_CONFIG_ITEMS_ERROR,
  REMOVE_TEAM_CONFIG_ITEMS_SUCCESS,
  REMOVE_TEAM_ERROR,
  REMOVE_TEAM_SUCCESS,
  REMOVE_USERS_FROM_TEAM,
  REMOVE_USERS_FROM_TEAM_ERROR,
  REMOVE_USERS_FROM_TEAM_SUCCESS,
  RENAME_TEAM,
  RENAME_TEAM_ERROR,
  RENAME_TEAM_SUCCESS,
  SET_CONTACT_TEAM_ROLE,
  SET_CONTACT_TEAM_ROLE_ERROR,
  SET_CONTACT_TEAM_ROLE_SUCCESS,
} from "../modules/team/actions/teamActions";
import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${window.config.REACT_APP_API_ENDPOINT}/api/Team`;

const teamApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case REMOVE_TEAM: {
      AuthFetch(
        fetch,
        `${apiEndpoint}/accountteams/delete/${action.payload.data}`,
        {
          method: "GET",
        }
      )
        .then((response) => {
          // console.log(response, action.payload.data)
          return next({
            type: REMOVE_TEAM_SUCCESS,
            payload: {
              data: action.payload.data,
            },
          });
        })
        .catch((err) => {
          // console.log('error:', err)
          return next({
            type: REMOVE_TEAM_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case RENAME_TEAM: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/renameteam`, {
        method: "POST",
        body: JSON.stringify({
          name: action.payload.data,
          teamId: action.payload.teamId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // console.log(response, action.payload.data)
          if (response.ok) {
            return next({
              type: RENAME_TEAM_SUCCESS,
              payload: {
                data: action.payload.data,
                teamId: action.payload.teamId,
              },
            });
          } else {
            return next({
              type: RENAME_TEAM_ERROR,
            });
          }
        })
        .catch((err) => {
          // console.log('error:', err)
          return next({
            type: RENAME_TEAM_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case REMOVE_TEAM_CONFIG_ITEMS: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/removeConfigItems`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: REMOVE_TEAM_CONFIG_ITEMS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: REMOVE_TEAM_CONFIG_ITEMS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case ADD_TEAM_CONFIG_ITEMS: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/addConfigItems`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: ADD_TEAM_CONFIG_ITEMS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ADD_TEAM_CONFIG_ITEMS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_TEAM_CONTRACTS: {
      AuthFetch(fetch, `${apiEndpoint}/teamContracts`)
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: GET_TEAM_CONTRACTS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_TEAM_CONTRACTS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case GET_ACCOUNT_TEAMS: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/${action.payload.data}`)
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: GET_ACCOUNT_TEAMS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_ACCOUNT_TEAMS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case CREATE_TEAM: {
      AuthFetch(fetch, `${apiEndpoint}/create`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: CREATE_TEAM_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: CREATE_TEAM_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case ADD_CONTRACTS_TO_TEAM: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/addcontracts`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: ADD_CONTRACTS_TO_TEAM_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ADD_CONTRACTS_TO_TEAM_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case ADD_USERS_TO_TEAM: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/addusers`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: ADD_USERS_TO_TEAM_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: ADD_USERS_TO_TEAM_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case MODIFY_CONTRACT_MAPPING: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/addticketmapping`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: MODIFY_CONTRACT_MAPPING_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: MODIFY_CONTRACT_MAPPING_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case REMOVE_CONTRACTS_FROM_TEAM: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/removecontract`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: REMOVE_CONTRACTS_FROM_TEAM_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: REMOVE_CONTRACTS_FROM_TEAM_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case REMOVE_USERS_FROM_TEAM: {
      AuthFetch(fetch, `${apiEndpoint}/accountteams/removeuser`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: REMOVE_USERS_FROM_TEAM_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: REMOVE_USERS_FROM_TEAM_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case SET_CONTACT_TEAM_ROLE: {
      AuthFetch(fetch, `${apiEndpoint}/setContactTeamRole`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: SET_CONTACT_TEAM_ROLE_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: SET_CONTACT_TEAM_ROLE_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    default:
      return store;
  }
};

export default teamApi;
