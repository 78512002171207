import "./wdyr";
import "./index.css";
import "@mui/material/styles/styled";

import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import App from "./containers/App";
import { StyledEngineProvider } from "@mui/material/styles";
import "sanitize.css";
import "./css/app.css";
import { telemetryInit } from "functions/telemetry";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { LicenseInfo } from "@mui/x-license";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ClaimsProvider } from "auth/ClaimsProvider";
import { createRoot } from "react-dom/client";
import { ThemeModeProvider } from "shared-ui/src/theme/ThemeModeProviderContext";
import { UserTelemetry } from "./v2/src/components/UserTelemetry";

LicenseInfo.setLicenseKey(
  "6589d270f97fa51b10853502299d4fedTz05MDM5NSxFPTE3NDc0MTc1MDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const target = document.getElementById("root");

const root = createRoot(target);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false, // default: true
    },
  },
});

telemetryInit();

if (window.config.REACT_APP_DIST_ENV !== "prod") {
  localStorage.setItem("debug", "app-debug:*");
}
root.render(
  <React.StrictMode>
    <ThemeModeProvider>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <BrowserRouter>
            <Auth0ProviderWithHistory>
              <QueryClientProvider client={queryClient}>
                <ClaimsProvider>
                  <ReactQueryDevtools />
                  <UserTelemetry />
                  <App />
                </ClaimsProvider>
              </QueryClientProvider>
            </Auth0ProviderWithHistory>
          </BrowserRouter>
        </Provider>
      </StyledEngineProvider>
    </ThemeModeProvider>
  </React.StrictMode>
);
