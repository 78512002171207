import {
  AceInvoiceRequest,
  EmailInvoicesRequest,
} from "../modules/rtk/invoice/invoiceTypes";
import { isDemoAccount } from "../functions/demoData";
import {
  AXInvoiceClient,
  EmailInvoicesDto,
  Invoice_Response as InvoiceResponse,
  InvoiceEmail_Response as InvoiceEmailResponse,
  ProjectInvoiceClient,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const invoiceApi = new AXInvoiceClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);
const projectInvoiceApi = new ProjectInvoiceClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestAllProjectInvoices(
  axNumber: string
): Promise<InvoiceResponse> {
  if (isDemoAccount(axNumber)) {
    return projectInvoiceApi.getProjectInvoices();
  }

  return invoiceApi.getInvoicesByTagAndProjectName("corp services", "all");
}

export async function getRestACEInvoices(
  aceInvoiceRequest: AceInvoiceRequest
): Promise<InvoiceResponse> {
  let serviceProjectName = "";
  if (aceInvoiceRequest.contractId && !aceInvoiceRequest.projectId) {
    serviceProjectName = `C${aceInvoiceRequest.contractId}:P`;
  } else if (aceInvoiceRequest.projectId && !aceInvoiceRequest.contractId) {
    serviceProjectName = `C:P${aceInvoiceRequest.projectId}`;
  } else if (aceInvoiceRequest.contractId && aceInvoiceRequest.projectId) {
    serviceProjectName = `C${aceInvoiceRequest.contractId}:P${aceInvoiceRequest.projectId}`;
  }

  return invoiceApi.getInvoicesByTagAndProjectName(
    "corp services",
    serviceProjectName
  );
}

export async function getRestAwsInvoices(): Promise<InvoiceResponse> {
  return invoiceApi.getInvoicesByTag("aws");
}

export async function getRestAzureInvoices(): Promise<InvoiceResponse> {
  return invoiceApi.getInvoicesByTag("azure");
}

export async function getRestGCPInvoices(): Promise<InvoiceResponse> {
  return invoiceApi.getInvoicesByTag("gcp");
}

export async function getRestO365Invoices(): Promise<InvoiceResponse> {
  return invoiceApi.getInvoicesByTag("office 365");
}

export async function postSendInvoicesToEmail(
  emailInvoicesRequest: EmailInvoicesRequest
): Promise<InvoiceEmailResponse> {
  return invoiceApi.requestInvoices(
    emailInvoicesRequest.tag,
    emailInvoicesRequest.projectName,
    emailInvoicesRequest.invoiceEmailRequest
  );
}

export async function sendInvoicesToEmail(
  emailInvoicesRequest: EmailInvoicesDto
): Promise<InvoiceEmailResponse> {
  return invoiceApi.sendInvoicesToEmail(emailInvoicesRequest);
}
