import { PermissionSet } from "modules/rtk/team/teamTypes";
import axios from "axios";
import {
  AccountTeamReponse,
  AddContacts,
  Team,
  TeamClient,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
import { GetAxiosHeaders } from "../auth/GetAxiosHeaders";
const baseUrl = `${(window as any).config.REACT_APP_API_ENDPOINT}/api/Team`;
export const api = new TeamClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestEnableTeams(accountId: number): Promise<Team[]> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.get<Team[]>(
    baseUrl + `/enableteams/${accountId}`,
    { headers: axiosHeaders }
  );

  return response.data;
}

export async function getRestTeamPermissions(
  teamId: number,
  accountId: number
): Promise<PermissionSet> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.get<PermissionSet>(
    baseUrl + `/sgPermissions/${accountId}/${teamId}`,
    { headers: axiosHeaders }
  );

  return response.data;
}

export async function postRestTeamPermissions(
  accountId: number,
  permissionSet: PermissionSet
): Promise<PermissionSet> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.post<PermissionSet>(
    baseUrl + `/sgPermissions/${accountId}`,
    permissionSet,
    { headers: axiosHeaders }
  );

  return response.data;
}
export async function getRestAccountTeams(accountId: number): Promise<Team[]> {
  return api.accountTeams(accountId);
}

export async function addUserToTeam(request: AddContacts): Promise<Team> {
  return api.addContacts(request);
}

export async function removeUserFromTeam(request: AddContacts): Promise<Team> {
  return api.removeContact(request);
}

export async function getRestMyTeams(): Promise<AccountTeamReponse> {
  return api.getMyTeams();
}
