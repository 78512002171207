import {
  GET_ALL_DOCUMENTS,
  GET_ALL_DOCUMENTS_SUCCESS,
  GET_ALL_DOCUMENTS_ERROR
} from "../modules/knowledgebase/actions/knowledgebaseActions";
import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${window.config.REACT_APP_API_ENDPOINT}/api/Knowledgebase/kb`;

const knowledgebaseApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case GET_ALL_DOCUMENTS: {
      AuthFetch(fetch, `${apiEndpoint}/all`)
        .then((response) => response.json())
          .then((data) => {
            return next({
              type: GET_ALL_DOCUMENTS_SUCCESS,
              payload: {
                data: data
              },
            });
          })
        .catch((err) => {
          return next({
            type: GET_ALL_DOCUMENTS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    default:
      return store;
  }
};

export default knowledgebaseApi;
