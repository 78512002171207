import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: "" });

const filterProfileForError = (profile, shiProfile) => {
  return {
    userProfileId: profile.id,
    name: profile.surname + ", " + profile.givenName,
    mail: profile.mail,
    contactId: profile.contact.id,
    accountId: profile.contact.accountID,
    accountName: profile.accountName,
    portalRole: profile.portalRole,
    shiProfile: shiProfile ? shiProfile.mail : false,
  };
};

class AppInsightsTelemetry {
  constructor() {
    this.reactPlugin = null;
    this.appInsights = null;
    this.isInit = false;
  }

  init = () => {
    if (this.isInit) return;

    this.reactPlugin = new ReactPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey:
          window.config.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        disableAjaxTracking: false,
        disableExceptionTracking: false,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });
    this.appInsights.loadAppInsights();
    this.isInit = true;
  };

  trackException = (
    uuid,
    errorMessage,
    browserType,
    userProfileResponse,
    router
  ) => {
    const error = {
      uuid: uuid,
      errorMessage: JSON.stringify(errorMessage),
      browser: browserType,
      profile: userProfileResponse
        ? filterProfileForError(
            userProfileResponse.userProfile,
            userProfileResponse.shiProfile
          )
        : null,
      router: router ? router : null,
    };
    const aiError = JSON.stringify(error);
    this.appInsights.trackException({
      error: new Error(aiError),
      severityLevel: SeverityLevel.Error,
      id: "Client Error",
    });
  };

  // We're not currently tying identifying user info with our App Insights data
  beginUser = (userProfileResponse) => {};
  endUser = () => {};
  beginRoute = (location, featureRoute) => {};
  trackEvent = (name, context) => {};
}

export const appInsightsTelemetry = window.config
  .REACT_APP_INSIGHTS_TELEMETRY_ENABLED
  ? new AppInsightsTelemetry()
  : null;
