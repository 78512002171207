import {
  DynamicTag,
  DynamicTagClient,
  DynamicTagRequest,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new DynamicTagClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestDynamicTags(): Promise<DynamicTag[]> {
  return api.getAccountDynamicTags();
}

export async function postRestDynamicTags(
  request: DynamicTagRequest
): Promise<DynamicTag[]> {
  return api.createDynamicTag(request);
}

export async function putRestDynamicTags(
  request: DynamicTagRequest
): Promise<DynamicTag[]> {
  return api.updateDynamicTag(request);
}

export async function deleteRestDynamicTags(
  request: number[]
): Promise<boolean> {
  return api.deleteDynamicTag(request);
}

export async function postRestAddTagsElasticSearch(
  request: DynamicTagRequest
): Promise<any> {
  return api.addTagsWithElastic(request);
}

export async function deleteRestTagsElasticSearch(
  request: DynamicTagRequest
): Promise<any> {
  return api.removeTagsWithElastic(request);
}
