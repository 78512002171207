import {
  SET_SUBDOMAIN,
  SET_SUBDOMAIN_SUCCESS,
  SET_SUBDOMAIN_ERROR,
  SET_WHITELABEL,
  SET_WHITELABEL_SUCCESS,
  SET_WHITELABEL_ERROR,
  GET_SITE,
  GET_SITE_SUCCESS,
  GET_SITE_ERROR,
} from "../modules/site/actions/siteActions";

import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${window.config.REACT_APP_API_ENDPOINT}/api/Site`;

const siteApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case GET_SITE: {
      fetch(`${apiEndpoint}/getsite`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return next({
              type: GET_SITE_ERROR,
            });
          }
        })
        .then((data) => {
          return next({
            type: GET_SITE_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: GET_SITE_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case SET_SUBDOMAIN: {
      AuthFetch(fetch, `${apiEndpoint}/subdomain`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log('response SET_SUBDOMAIN:', data)
          return next({
            type: SET_SUBDOMAIN_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: SET_SUBDOMAIN_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case SET_WHITELABEL: {
      AuthFetch(fetch, `${apiEndpoint}/whitelabel`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log('response SET_WHITELABEL:', data)
          return next({
            type: SET_WHITELABEL_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: SET_WHITELABEL_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    default:
      return store;
  }
};

export default siteApi;
