import {
  AllStatusesResponse,
  LinkedAccountResponse,
  CancelPost,
  InvitePost,
  CreatePost,
  ConfirmExpirationPost,
  StatusPost,
} from "../modules/rtk/awsAccounts/awsAccountTypes";
import axios from "axios";
import { GetAxiosHeaders } from "../auth/GetAxiosHeaders";

const baseUrl = `${
  (window as any).config.REACT_APP_API_ENDPOINT
}/api/aws/Account`;

export async function getRestAllPendingAccounts(): Promise<AllStatusesResponse> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.get<AllStatusesResponse>(
    baseUrl + "/status/all",
    {
      headers: axiosHeaders,
    }
  );

  return response.data;
}

export async function postRestAccountStatus(
  statusRequest: StatusPost
): Promise<LinkedAccountResponse> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.post<LinkedAccountResponse>(
    baseUrl + "/status",
    JSON.stringify(statusRequest),
    {
      headers: axiosHeaders,
    }
  );

  return response.data;
}

export async function postRestCreateAccount(
  createRequest: CreatePost
): Promise<LinkedAccountResponse> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.post<LinkedAccountResponse>(
    baseUrl + "/create",
    JSON.stringify(createRequest),
    {
      headers: axiosHeaders,
    }
  );

  return response.data;
}

export async function postRestInviteAccount(
  inviteRequest: InvitePost
): Promise<LinkedAccountResponse> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.post<LinkedAccountResponse>(
    baseUrl + "/invite",
    JSON.stringify(inviteRequest),
    {
      headers: axiosHeaders,
    }
  );

  return response.data;
}

export async function postRestCancelAccountAction(
  cancelRequest: CancelPost
): Promise<LinkedAccountResponse> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.post<LinkedAccountResponse>(
    baseUrl + "/cancel",
    JSON.stringify(cancelRequest),
    {
      headers: axiosHeaders,
    }
  );

  return response.data;
}

export async function postRestConfirmExpiration(
  confirmationRequest: ConfirmExpirationPost
): Promise<LinkedAccountResponse> {
  const axiosHeaders = await GetAxiosHeaders();

  const response = await axios.post<LinkedAccountResponse>(
    baseUrl + "/complete-cancel",
    JSON.stringify(confirmationRequest),
    {
      headers: axiosHeaders,
    }
  );

  return response.data;
}
