import { datadogRum } from "@datadog/browser-rum";
import { applicationBuild } from "./applicationBuild";

class DatadogTelemetry {
  constructor() {
    this.isInit = false;
  }

  init = () => {
    if (this.isInit) return;

    datadogRum.init({
      applicationId: "9c54a722-2a27-45bb-96df-a46596d1a548",
      clientToken: "pub04082f71f90fd9cdfa322f2e1b87b9b7",
      site: "datadoghq.com",
      service: "shi-one",
      env: window.config.REACT_APP_DIST_ENV,
      proxyUrl: `${window.config.REACT_APP_API_ENDPOINT}/api/metrics`,
      version: applicationBuild.version,
      sampleRate: 100,
      sessionReplaySampleRate: 20,
      trackViewsManually: true,
      trackInteractions: true,
      actionNameAttribute: "data-telemetry-name",
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
    this.isInit = true;
  };

  trackException = (
    uuid,
    errorMessage,
    browserType,
    userProfileResponse,
    router
  ) => {
    // Datadog is automatically hooking in to capture exceptions.No action needed here.
  };

  beginUser = (userProfileResponse) => {
    if (datadogRum.getUser()) {
      datadogRum.clearUser();
    }

    const portalRole =
      userProfileResponse.userProfile.contact?.userDefinedFieldsJson &&
      JSON.parse(
        userProfileResponse.userProfile.contact.userDefinedFieldsJson
      ).find((k) => {
        return k.Name === "Portal Role";
      });

    // DO NOT include user email or first or last name.
    // Doing so violates our internal privacy policies as well as making us out of compliance with GDPR.
    let user = {
      accountId: userProfileResponse.userProfile.contact?.accountID,
      accountName: userProfileResponse.userProfile.accountName,
      shiDivision: userProfileResponse.accountDivision,
      shiDistrict: userProfileResponse.accountDistrict,
      portalRole: portalRole?.Value,
      impersonation: userProfileResponse.shiProfile ? true : false,
      virtualAdmin: userProfileResponse.virtualAdmin ? true : false,
      contactId: userProfileResponse.userProfile.contact?.id ?? -1,
    };

    if (userProfileResponse.userProfile.id > 0) {
      user.id = userProfileResponse.userProfile.id.toString();
    }

    if (userProfileResponse.virtualAdmin) {
      user.id = userProfileResponse.userProfile.mail; // Not a real person, so email is OK
    }

    if (userProfileResponse.shiProfile) {
      user.shi = {
        id: userProfileResponse.shiProfile.id.toString(),
        contactId: userProfileResponse.shiProfile.contact?.id ?? -1,
      };
    }

    datadogRum.setUser(user);
  };

  endUser = () => {
    datadogRum.clearUser();
  };

  beginRoute = (location, featureRoute) => {
    datadogRum.startView({
      name: featureRoute.path ? featureRoute.path : location.pathname,
    });
  };

  trackEvent = (name, context) => {
    datadogRum.addAction(name, context);
  };
}

export const datadogTelemetry = window.config
  .REACT_APP_DATADOG_TELEMETRY_ENABLED
  ? new DatadogTelemetry()
  : null;
