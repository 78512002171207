import {
  FETCH_CONTRACTSLAS,
  FETCH_CONTRACTSLAS_SUCCESS,
  FETCH_CONTRACTSLAS_ERROR,
  CREATE_CONTRACTSLAS,
  CREATE_CONTRACTSLAS_SUCCESS,
  CREATE_CONTRACTSLAS_ERROR,
  UPDATE_CONTRACTSLAS,
  UPDATE_CONTRACTSLAS_SUCCESS,
  UPDATE_CONTRACTSLAS_ERROR,
} from "../modules/mapping/actions/contractMappingActions";
import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${window.config.REACT_APP_API_ENDPOINT}/api/mapping`;

const mappingApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case FETCH_CONTRACTSLAS: {
      AuthFetch(fetch, `${apiEndpoint}/contractslas/get`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.responseStatus !== 1) {
            return next({
              type: FETCH_CONTRACTSLAS_ERROR,
              payload: {
                data: data,
              },
            });
          }
          return next({
            type: FETCH_CONTRACTSLAS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: FETCH_CONTRACTSLAS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case CREATE_CONTRACTSLAS: {
      // console.log('trying to create contract sla (api)')
      AuthFetch(fetch, `${apiEndpoint}/contractslas/create`, {
        method: "POST",
        body: JSON.stringify(action.payload.data.request),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log('Created!')
          if (data.responseStatus !== 1) {
            return next({
              type: CREATE_CONTRACTSLAS_ERROR,
              payload: {
                data: data,
              },
            });
          }
          return next({
            type: CREATE_CONTRACTSLAS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: CREATE_CONTRACTSLAS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    case UPDATE_CONTRACTSLAS: {
      AuthFetch(fetch, `${apiEndpoint}/contractslas/update`, {
        method: "POST",
        body: JSON.stringify(action.payload.data.request),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log('Updated!')
          if (data.responseStatus !== 1) {
            return next({
              type: UPDATE_CONTRACTSLAS_ERROR,
              payload: {
                data: data,
              },
            });
          }
          return next({
            type: UPDATE_CONTRACTSLAS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          return next({
            type: UPDATE_CONTRACTSLAS_ERROR,
            payload: {
              data: err,
            },
          });
        });
      break;
    }
    default:
      return store;
  }
};

export default mappingApi;
