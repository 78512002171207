import {
  AwsSimpleRecommendationsClient,
  ReservationPurchaseResult,
  RightSizingResult,
  SavingsPlansPurchaseResult,
  TrustedAdvisorResult,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new AwsSimpleRecommendationsClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestTrustedAdvisor(): Promise<TrustedAdvisorResult[]> {
  return api.getTrustedAdvisor();
}

export async function getRestReservationPurchaseRecommendations(): Promise<
  ReservationPurchaseResult[]
> {
  return api.getReservationPurchaseRecommendations();
}

export async function getRestRightsizingRecommendations(): Promise<
  RightSizingResult[]
> {
  return api.getRightsizingRecommendations();
}

export async function getRestSavingsPlanRecommendations(): Promise<
  SavingsPlansPurchaseResult[]
> {
  return api.getSavingsPlanRecommendations();
}
