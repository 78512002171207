import { AssessmentsClient } from "@lib/ShiOneClient";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";

const api = new AssessmentsClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT,
);

const useDeleteAssessmentIntegration = async (integrationId: number) => {
  const result = api.deleteAssessmentInAirtable(integrationId);
  return await result;
};

export default useDeleteAssessmentIntegration;
