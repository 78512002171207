import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { EditOff, ErrorOutlined, VerifiedOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetBearerToken } from "../../../../../auth/BearerToken";
import SHIFilePond, {
  SHIFilePondProps,
} from "shared-ui/src/components/SHIFilePond";
import { MappingFileType, MappingRowsResultDto } from "@lib/ShiOneClient";
import { combineReducers } from "./combineReducers";
import { mappingActions, mappingReducer } from "./mappingReducer";
import {
  excelSheetActions,
  excelSheetReducer,
  locationPatterns,
} from "./excelSheetReducer";
import useMappingEngineSchema, { MappingCol } from "./useMappingEngineSchema";
import DateFormatAutocomplete from "./DateFormatAutoComplete";
import { Editor } from "@monaco-editor/react";
import { useClaims } from "../../../../../auth/ClaimsProvider";

export interface ExcelSheet {
  name: string;
  sourceSheetName: string;
  locationPattern: locationPatterns;
  locationKey: string;
  id: string;
  valid: false;
}

export interface MappingRow {
  excelSheet: ExcelSheet;
  column: string;
  aliasName: string;
  editMode: boolean;
  mappedCol: MappingCol;
  mappedColDateFormat: string | null;
  naOverride: boolean;
  id: string;
  valid: boolean;
}

type MappingStatus = "draft" | "published";

interface BaseMapping {
  version: string;
  vendorName: string;
  status: MappingStatus;
  naOverrides: string[];
}

const initState = {
  mappings: [],
  excelSheets: [],
};

const rootReducer = combineReducers({
  mappings: mappingReducer,
  excelSheets: excelSheetReducer,
});

export default function AddMapping() {
  const { isShi } = useClaims();

  const [state, dispatch] = useReducer(rootReducer, initState);
  const [files, setFiles] = useState([]);
  const [uploadResponse, setUploadResponse] = useState<MappingRowsResultDto>();
  const [token, setToken] = useState(null);

  const { data, isLoading, isError } = useMappingEngineSchema({
    url: "https://shionestorage.blob.core.windows.net/shione/sample-mapping-schema.json",
  });

  const handleMappingChange = (
    e: { target: { name: any; value: any } },
    id: any
  ) => {
    dispatch({
      type: mappingActions.update_mapping,
      name: e.target.name,
      value: e.target.value,
      id: id,
    });
  };

  const handleAutoCompleteMappingChange = (name, value, id: any) => {
    dispatch({
      type: mappingActions.update_mapping,
      name: name,
      value: value,
      id: id,
    });
  };

  const mappingValue = () => {
    const validItems = state?.mappings?.map((item: MappingRow) => {
      if (item?.valid) {
        return item;
      }
    });

    const validSheets = state?.excelSheets?.map((item: ExcelSheet) => {
      if (item?.valid) {
        return item;
      }
    });

    const mappingJson = {
      status: "draft",
      fileType: uploadResponse?.fileType,
      excelSheets: [],
      mappings: [],
    };

    validSheets.forEach((item: ExcelSheet) => {
      mappingJson.excelSheets.push({
        locationPattern: item?.locationPattern,
        locationKey: item?.locationKey,
      });
    });
    validItems.forEach((item: MappingRow) => {
      const mappingObj = {
        sourceColumn: item?.column,
        locationKey: item?.excelSheet.locationKey,
        targetColumn: item?.mappedCol?.name,
        targetColumnType: item?.mappedCol.type,
        naOverride: item?.naOverride,
      };
      if (item?.mappedColDateFormat) {
        mappingObj["sourceColDateFormat"] = item?.mappedColDateFormat;
      }
      mappingJson.mappings.push(mappingObj);
    });

    return JSON.stringify(mappingJson);
  };

  const editMode = (item: MappingRow) => {
    if (item.editMode) {
      return (
        <>
          <Select
            fullWidth
            displayEmpty
            variant={"outlined"}
            defaultValue={""}
            disabled={item.naOverride}
            onChange={(e) => handleMappingChange(e, item.id)}
            name={"excelSheet"}
            value={item.excelSheet}
          >
            <MenuItem value={""} disabled>
              Select a Sheet
            </MenuItem>
            {/*// @ts-ignore*/}
            {state?.excelSheets?.map((excelSheet: ExcelSheet) => {
              if (excelSheet.valid) {
                return (
                  <MenuItem value={excelSheet}>
                    {excelSheet.locationKey}
                  </MenuItem>
                );
              }
            })}
          </Select>

          {item.excelSheet.locationKey.length > 0 && (
            <Select
              fullWidth
              variant={"outlined"}
              defaultValue={""}
              displayEmpty
              name={"column"}
              value={item.column}
              disabled={item.naOverride}
              renderValue={(p) => p}
              onChange={(e) => handleMappingChange(e, item.id)}
            >
              <MenuItem value={""} disabled>
                Select a column
              </MenuItem>
              {[
                ...new Set(
                  uploadResponse?.excelRowsList?.map((row) => {
                    if (
                      item.excelSheet.locationPattern ===
                      locationPatterns.startsWith
                    ) {
                      if (
                        row.sheetName?.startsWith(item.excelSheet.locationKey)
                      ) {
                        return {
                          colName: row.columnName,
                          exampleData: row.exampleData,
                        };
                      }
                    } else {
                      if (row.sheetName === item.excelSheet.locationKey) {
                        return {
                          colName: row.columnName,
                          exampleData: row.exampleData,
                        };
                      }
                    }

                    return null;
                  })
                ),
              ].map((item) => (
                <MenuItem
                  value={item?.colName}
                  sx={{ flexDirection: "column", alignItems: "start" }}
                >
                  <div>{item?.colName}</div>
                  {item?.exampleData?.map((i) => (
                    <Typography variant={"caption"} color={"textSecondary"}>
                      {i}
                    </Typography>
                  ))}
                </MenuItem>
              ))}
            </Select>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={item.naOverride}
                onChange={() => {
                  dispatch({
                    id: item.id,
                    type: mappingActions.na_override,
                  });
                }}
              />
            }
            label={"N/A Override"}
          />
        </>
      );
    }
    return (
      <div className={"flex text-center grow hover:cursor-pointer "}>
        <Typography
          variant={"body2"}
          className={"text-center"}
          onClick={() => toggleEditMode(item)}
        >
          {item.aliasName.length === 0
            ? item.locationKey + " - " + item.column
            : item.aliasName}
        </Typography>
      </div>
    );
  };

  const toggleEditMode = (item: MappingRow) => {
    dispatch({
      type: mappingActions.update_mapping,
      name: "editMode",
      value: !item.editMode,
      id: item.id,
    });
  };

  const generateMappingRow = (item: MappingRow) => {
    return (
      <div className={"flex pb-6 gap-3 w-full items-center"} key={item.id}>
        <Button
          startIcon={item.editMode ? <EditOff /> : <EditIcon />}
          onClick={() => toggleEditMode(item)}
        />
        <div>
          {item.valid ? (
            <VerifiedOutlined color={"success"} />
          ) : (
            <ErrorOutlined color={"error"} />
          )}
        </div>
        <Card className={"w-4/12 grow"}>
          <div className={"flex grow justify-between gap-3 p-2"}>
            {editMode(item)}
          </div>
        </Card>
        <Card className={"w-4/12 grow"}>
          {item.editMode && (
            <div className={"flex gap-3 p-2"}>
              <Select
                className={"h-full"}
                fullWidth
                variant={"outlined"}
                defaultValue={""}
                displayEmpty
                name={"mappedCol"}
                value={item.mappedCol}
                onChange={(e) => handleMappingChange(e, item.id)}
              >
                <MenuItem value={""} disabled>
                  Select a column
                </MenuItem>
                {data?.columns?.map((i) => {
                  return (
                    <MenuItem key={i.name} value={i}>
                      {i.name}
                    </MenuItem>
                  );
                })}
              </Select>

              {item.mappedCol.type === "date" && (
                <DateFormatAutocomplete
                  value={item.mappedColDateFormat}
                  setValue={(e: { target: any }) => {
                    handleAutoCompleteMappingChange(
                      "mappedColDateFormat",
                      e.target.innerText,
                      item.id
                    );
                  }}
                />
              )}
            </div>
          )}
          {!item.editMode && (
            <Typography className={"text-center"}>
              {item?.mappedCol?.name}
            </Typography>
          )}
        </Card>
        {item.editMode && (
          <Button
            startIcon={<DeleteIcon />}
            color={"error"}
            onClick={() =>
              dispatch({ type: mappingActions.delete_mapping, id: item.id })
            }
          >
            Delete Mapping
          </Button>
        )}
      </div>
    );
  };

  const handleAddRow = () => {
    dispatch({ type: mappingActions.add_mapping });
  };

  useEffect(() => {
    (async () => {
      // @ts-ignore
      setToken(await GetBearerToken());
    })();
  }, []);

  const filePondServer: SHIFilePondProps = {
    server: {
      url: `${(window as any).config.REACT_APP_API_ENDPOINT}/api/MappingEngine`,
      timeout: 230000,
      process: {
        method: "POST",
        withCredentials: false,
        timeout: 230000,
        onload: (response: any) => {
          const jsonResponse = JSON.parse(response);
          setUploadResponse(jsonResponse);
          return jsonResponse?.guid;
        },
        onerror: (response: any) => {
          return response.data;
        },
        ondata: (formData: any) => {
          return formData;
        },
        headers: { Authorization: "Bearer " + token },
        revert: {
          url: "",
          headers: { Authorization: "Bearer " + token },
        },
      },
    },
  };

  const handleFileChange = (files: any) => {
    setFiles(files);
  };

  const handleAddNewSheet = () => {
    dispatch({ type: "add_excel" });
  };

  const handleExcelSheetChange = (
    e: { target: { name: any; value: any } },
    id: any
  ) => {
    dispatch({
      type: excelSheetActions.update_excel,
      name: e.target.name,
      value: e.target.value,
      id: id,
    });
  };

  const generateExcelRow = (excelSheet: ExcelSheet) => {
    return (
      <div
        className={"flex pb-6 gap-3 mx-4 mt-4  items-center"}
        key={excelSheet.id}
      >
        {excelSheet.valid ? (
          <VerifiedOutlined color={"success"} />
        ) : (
          <ErrorOutlined color={"error"} />
        )}
        {/*<TextField*/}
        {/*  label={"Sheet Alias"}*/}
        {/*  name={"name"}*/}
        {/*  value={item.name}*/}
        {/*  onChange={(e) => handleExcelSheetChange(e, item.id)}*/}
        {/*/>*/}
        <FormControl className={"grow"}>
          <InputLabel id={"source-sheet-label"}>Source Sheet Name</InputLabel>
          <Select
            labelId={"source-sheet-label"}
            label={"Source Sheet Name"}
            variant={"outlined"}
            defaultValue={""}
            onChange={(e) => handleExcelSheetChange(e, excelSheet.id)}
            name={"sourceSheetName"}
            value={excelSheet.sourceSheetName}
          >
            {[
              ...new Set(
                uploadResponse?.excelRowsList?.map((row) => row.sheetName)
              ),
            ].map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={"grow"}>
          <InputLabel id={"location-pattern-label"}>
            Location Pattern
          </InputLabel>
          <Select
            labelId={"location-pattern-label"}
            label={"Location Pattern"}
            variant={"outlined"}
            defaultValue={""}
            onChange={(e) => handleExcelSheetChange(e, excelSheet.id)}
            name={"locationPattern"}
            value={excelSheet.locationPattern}
          >
            <MenuItem value={locationPatterns.exact}>Exact Match</MenuItem>
            <MenuItem value={locationPatterns.startsWith}>Starts With</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={"Location Key"}
          name={"locationKey"}
          value={excelSheet.locationKey}
          onChange={(e) => handleExcelSheetChange(e, excelSheet.id)}
        />
        <Button
          startIcon={<DeleteIcon />}
          color={"error"}
          onClick={() =>
            dispatch({
              type: excelSheetActions.delete_excel,
              id: excelSheet.id,
            })
          }
        >
          Delete Sheet
        </Button>
      </div>
    );
  };

  if (!isShi()) {
    return false;
  }

  return (
    <>
      <div className={"flex justify-between"}>
        <div>
          <TextField label={"Enter Vendor Name"} className={"mr-4"} />
          <Typography component={"span"}>Input</Typography>
        </div>

        <FormControl className={"min-w-32 "}>
          <InputLabel id={"version-label"}>Version</InputLabel>
          <Select
            fullWidth
            label={"Version"}
            variant={"outlined"}
            disabled
            defaultValue={1}
            labelId={"version-label"}
          >
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={"mb-8 mt-4"}>
        <SHIFilePond
          server={filePondServer.server}
          setFiles={handleFileChange}
          files={files}
        />
        FileType: {uploadResponse?.fileType}
      </div>
      {uploadResponse?.fileType === MappingFileType.Excel && (
        <Card className={"mt-4"}>
          <Typography variant={"h6"} className={"text-center pt-4"}>
            Available Sheets
          </Typography>
          {/*// @ts-ignore*/}
          {state?.excelSheets?.map((i) => {
            return generateExcelRow(i);
          })}
          <Button onClick={handleAddNewSheet}>Add New sheet</Button>
        </Card>
      )}
      <div className={"flex justify-between"}>
        <div className={"mt-8"}>
          {/*// @ts-ignore*/}
          {state?.mappings?.map((i) => {
            return generateMappingRow(i);
          })}
          <Button onClick={handleAddRow}>Add Row</Button>
        </div>

        <Editor
          className={"mt-4"}
          height={"800px"}
          defaultLanguage={"json"}
          value={mappingValue()}
          options={{
            readOnly: true,
            automaticLayout: true,
            folding: true,
            formatOnType: true,
            formatOnPaste: true,
            minimap: { enabled: false },
          }}
        />
      </div>
    </>
  );
}
