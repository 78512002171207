import React, { useState, useEffect } from "react";
import { ApiClientConfig } from "../auth/ApiClientConfig";
import { ArchbeeClient } from "../lib/ShiOneClient";
import Loading from "./Loading";
const api = new ArchbeeClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

async function fetchUrl(): Promise<string> {
  return api.getArchbeeLink();
}

function ArchbeeShiInternal() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const url = await fetchUrl();
      window.location.href = url;
      setIsLoading(false);
    }

    fetchData().then();
  }, []);

  return <div>{isLoading ? <Loading /> : <p>Redirecting...</p>}</div>;
}

export default ArchbeeShiInternal;
