import {
  GET_BACKUP_STATS,
  GET_BACKUP_STATS_ERROR,
  GET_BACKUP_STATS_SUCCESS,
} from "../modules/backup/actions/backupActions";

import fetch from "isomorphic-fetch";
import AuthFetch from "../auth/AuthFetch";

const apiEndpoint = `${window.config.REACT_APP_API_ENDPOINT}/api/backup`;

const backupApi = (store) => (next) => (action) => {
  next(action);
  switch (action.type) {
    case GET_BACKUP_STATS: {
      AuthFetch(fetch, `${apiEndpoint}/order/backupstats`, {
        method: "POST",
        body: JSON.stringify(action.payload.data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return next({
            type: GET_BACKUP_STATS_SUCCESS,
            payload: {
              data: data,
            },
          });
        })
        .catch((err) => {
          if (!Object.keys(err).length) {
            return next({
              type: GET_BACKUP_STATS_ERROR,
              payload: {
                data: "Error",
              },
            });
          } else {
            return next({
              type: GET_BACKUP_STATS_ERROR,
              payload: {
                data: "",
              },
            });
          }
        });
      break;
    }
    default:
      return store;
  }
};

export default backupApi;
