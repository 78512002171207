import {
  GetWarehouseOrdersResponse,
  GetWarehouseStockResponse,
  WarehouseClient,
} from "@lib/ShiOneClient";
import { ApiClientConfig } from "../../../../../auth/ApiClientConfig";

const api = new WarehouseClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getWarehouseStock(): Promise<GetWarehouseStockResponse> {
  return api.getWarehouseStock();
}

export async function getWarehouseOrders(): Promise<GetWarehouseOrdersResponse> {
  return api.getWarehouseOrders();
}
