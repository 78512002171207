import { ConfigurationItemDTO, DeviceClient } from "../lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new DeviceClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestMyDevices(): Promise<ConfigurationItemDTO[]> {
  return api.getDevice();
}
