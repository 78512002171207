import {  BookmarkClient, BookmarkDto, BookmarksDto } from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";

const api = new BookmarkClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestBookmarks(reportId: string): Promise<BookmarksDto> {
 if (!reportId) return new BookmarksDto();
   return api.getAllBookmarks(reportId);
}

export async function postRestAddBookmark(
  bookmark: BookmarkDto
): Promise<BookmarkDto> {
  return api.addBookmark( bookmark);
}

export async function putRestUpdateBookmark(bookmark: BookmarkDto): Promise<void> {
  return api.updateBookmark( bookmark );
}

export async function deleteRestDeleteBookmark(
 bookmark: BookmarkDto
): Promise<void> {
    return api.deleteBookmark( bookmark);

}
