import {
  AzureSimpleRecommendationsClient,
  ResourceRecommendation,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new AzureSimpleRecommendationsClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestAzureSimpleRecommendations(
  subscriptionId: string
): Promise<ResourceRecommendation[]> {
  return api.getAzureSimpleRecommendationsBySubscriptionId(subscriptionId);
}
