import {
  IntegrationClient,
  IntegrationDefinition,
  IntegrationDTO,
  CustomerAccount,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
import { CancelToken } from "axios";
const integrationApi = new IntegrationClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestIntegrationsCollection(
  cancelToken?: CancelToken | undefined
): Promise<IntegrationDTO[]> {
  return integrationApi.getIntegrations(false, cancelToken);
}

export async function getRestIntegrationDefinitions(): Promise<
  IntegrationDefinition[]
> {
  return integrationApi.getIntegrationDefinitions(true);
}

export async function postActivateIntegration(
  integrationDto: IntegrationDTO[]
): Promise<IntegrationDTO[]> {
  return integrationApi.toggleIntegrationActivation(true, integrationDto);
}

export async function postDeactivateIntegration(
  integrationDto: IntegrationDTO[]
): Promise<IntegrationDTO[]> {
  return integrationApi.toggleIntegrationActivation(false, integrationDto);
}

export async function postRegisterIntegration(
  integrationDto: IntegrationDTO
): Promise<IntegrationDTO> {
  return integrationApi.registerIntegration(integrationDto);
}

export async function postUpdateRegistration(
  integrationDto: IntegrationDTO
): Promise<IntegrationDTO> {
  return integrationApi.updateIntegration(integrationDto);
}

export async function httpDeleteRegistration(
  integrationId: number
): Promise<void> {
  return integrationApi.deleteIntegration(integrationId);
}

export async function getRestIntegrationAccounts(
  cancelToken?: CancelToken | undefined
): Promise<CustomerAccount[]> {
  return integrationApi.getIntegrationAccounts();
}
